<template>
  <div class="card p-5 card-citmer cardRadius">
    <div class="text-end d-flex mb-3 justify-content-end">
      <date-picker
        v-model="date"
        :format="'DD/MM/YYYY'"
        :input-class="'form-control inputsGeneralClass w-100'"
        placeholder="Seleccione un rango de fecha"
        @change="getLaboratories"
        range />
    </div>
    <b-table
      :items="items"
      :fields="fields"
      :table-class="'patientsHeadClass'"
      responsive
      striped
      borderless>
      <template v-slot:cell(adon)="row">
        <div class="statusPoint" :class="colorStatus(row.item.status)"></div>
      </template>
      <template v-slot:cell(laboratory_type)="row">
        <span v-text="translateLaboratories(row.item.laboratory_type)"></span>
      </template>
      <template v-slot:cell(actions)="row">
        <template>
          <font-awesome-icon
            v-b-modal.resultsCard
            @click="chargeData(row.item)"
            class="mx-2 text-secondary pointer tableSizeIcon"
            :icon="iconByUserType" />
        </template>
      </template>
      <!-- ACTIONS / -->
    </b-table>
    <b-modal
      ref="resultsCard"
      id="resultsCard"
      size="xl"
      centered
      :body-class="'p-0'"
      :title="'Solicitud'"
      :title-class="'titles'"
      header-close-content=""
      :header-class="['titles']"
      hide-footer>
      <Results :ableEdit="ableEdit" :laboratory="laboratory" />
    </b-modal>
  </div>
</template>

<script>
import Results from "@/components/patient/details/laboratories/Results.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapGetters } from "vuex";

export default {
  name: "LaboratoryComponent",
  components: {
    Results,
    DatePicker,
  },
  data() {
    return {
      date: [new Date(), new Date()],
      items: [],
      fields: [
        {
          class: "text-center",
          key: "adon",
          label: "",
        },
        {
          class: "text-center",
          key: "medic_initials",
          label: "Solicitante",
        },
        {
          class: "text-center",
          key: "biologist_initials",
          label: "Biol.",
        },
        {
          class: "text-center",
          key: "filiation",
          label: "Filiación",
        },
        {
          class: "text-center",
          key: "nec",
          label: "NEC",
        },
        {
          class: "text-center",
          key: "date",
          label: "Fecha",
        },
        {
          class: "text-center",
          key: "patient_complete_name",
          label: "Paciente",
        },
        {
          class: "text-center",
          key: "laboratory_type",
          label: "Servicio",
        },
        {
          class: "text-center",
          key: "actions",
          label: "",
        },
      ],
      laboratory: {},
    };
  },
  mounted() {
    this.getLaboratories();
  },
  methods: {
    colorStatus(status) {
      const colorPoint = {
        results: "paidCitmer",
        pending: "expiredCitmer",
        default: "",
      };
      return colorPoint[status] || colorPoint.default;
    },
    getLaboratories() {
      const payload = {};
      [payload.date_ini] = this.date;
      [, payload.date_end] = this.date;
      this.$api.patient.getLaboratories(payload).then((res) => {
        this.items = res.data.laboratories;
      });
    },
    translateLaboratories(translate) {
      const laboratories = {
        freezing: "Congelación",
        spermogram: "Espermograma",
        pending: "Pendiente",
        results: "Resultados",
        default: "",
      };
      return laboratories[translate] || laboratories.default;
    },
    chargeData(item) {
      this.laboratory = item;
    },
  },
  computed: {
    ...mapGetters(["getUserType"]),
    ableEdit() {
      return this.getUserType !== "manager" && this.getUserType !== "admin";
    },
    iconByUserType() {
      if (this.ableEdit) return ["fa", "edit"];
      return ["fa", "eye"];
    },
  },
};
</script>
